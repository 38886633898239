

export default class Gallery {

    private readonly elem: HTMLElement;

    constructor(elem: HTMLElement) {
        this.elem = elem;
        this.init();
    }

    public static factory() {
        document.querySelectorAll('.gallery').forEach((e: HTMLElement) => new Gallery(e));
    }

    private init(): void {
        this.elem.querySelectorAll('a').forEach((e: HTMLElement) => {
            e.setAttribute('data-lightbox', this.elem.id);
        });
    }
}