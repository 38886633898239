export default class SiteHeader {
    private readonly el: HTMLElement;
    private isSticky = false;
    private isMobileNavVisible = false;
    private lastScrollTop = 0;
    private delta = 5;

    constructor(el: HTMLElement) {
        this.el = el;

        this.init();
    }

    public init(): void {
        this.checkHeader();
        this.addListeners();
    }

    public addListeners(): void {
        window.addEventListener('scroll', this.handleWindowScroll);

        //this.el.querySelector('.site-header__toggle-button').addEventListener('click', this.toggleMobileNav)
    }

    public toggleMobileNav = (): void => {
        if (this.isMobileNavVisible) {
            this.el.querySelector('.site-header__col-nav').classList.remove('site-header__col-nav--is-mobile-visible');
            this.el.querySelector('.site-header__toggle-button').classList.remove('site-header__toggle-button--close');
            this.isMobileNavVisible = false;
        } else {
            this.el.querySelector('.site-header__col-nav').classList.add('site-header__col-nav--is-mobile-visible');
            this.el.querySelector('.site-header__toggle-button').classList.add('site-header__toggle-button--close');
            this.isMobileNavVisible = true;
        }
    }

    private handleWindowScroll = (): void => {
        this.checkHeader();
    }

    private checkHeader = (): void => { 
        const st = window.pageYOffset;
    
        // Make sure they scroll more than delta
        if(Math.abs(this.lastScrollTop - st) <= this.delta)
            return;
        
        // If scrolled down and are past the navbar, add class .nav-up.
        /*if (st > this.lastScrollTop && st > this.el.offsetHeight){
            // Scroll Down
            this.el.classList.remove('nav-down');
            this.el.classList.add('site-header--hide');
            this.el.classList.remove('site-header--top');
        } else {
            // Scroll Up
            if (st + window.innerHeight < document.documentElement.scrollHeight) {
                this.el.classList.remove('site-header--hide');
                this.el.classList.add('nav-down');
            }

            // Add class to tell styling that scroll has reached top
            if (st === 0){
                this.el.classList.add('site-header--top');
            }
        }*/
        
        this.lastScrollTop = st;
    }
}