import $ from 'jquery';

export function SubmenuToggle(selector = '.dropdown-toggle') {
  const $element = $(selector);
  const OPEN_CLASS = 'is-open';
  let openCount = 0;
  const $mobileMenu = $('.dropdown-menu');
  function toggle($target, element) {
    console.log(openCount)

    $target.toggleClass(OPEN_CLASS);
    $(element).toggleClass(OPEN_CLASS);

    let expanded = $target.attr('aria-expanded');
    if(expanded == 'false') {
      openCount++;
      expanded = 'true';
    } else {
      openCount--;
      expanded = 'false';
    }
    $target.attr('aria-expanded', expanded);
    // $target.parent().focus();
    if(openCount) {
      $mobileMenu.addClass(OPEN_CLASS);
    } else {
      $mobileMenu.removeClass(OPEN_CLASS);
    }
  }

  function handleClick(e) {
    e.preventDefault();
    e.stopPropagation();
    const element = e.currentTarget;
    const target = e.currentTarget;
    console.log(target)
    toggle($(target), element);
  }

  $element.click((e) => handleClick(e))
}