/*eslint-disable*/
import $ from 'jquery';

export class Toggle {
  constructor(trigger, settings = {}) {
    let defaultSettings = {
      disableScroll: false
    }
    this.settings = {
      ...defaultSettings,
      ...settings
    }
    this.openClass = 'is-open';
    this.element = trigger;
    this.trigger = $(trigger);
    $('body').on('click', trigger, (e) => this.toggle(e));
  }
  preventDefault(e) {
    e.preventDefault();
    e.stopPropagation();
  }
  getTarget(trigger) {
    return [
      $(trigger).data('target'),
      $($(trigger).data('target'))
    ];
  }

  toggle(e) {
    this.preventDefault(e);
    $(e.currentTarget).toggleClass(this.openClass);
    let [$targetString, $target] = this.getTarget(e.currentTarget);
    $target.toggleClass(this.openClass);
    var expanded = $target.attr('aria-expanded');

    if(expanded == 'false') {
      expanded = 'true';
      $target.focus();
      if(this.settings.disableScroll) {
        $(document.body).addClass('no-scroll');
      }
    } else {
      expanded = 'false';
      if(this.settings.disableScroll) {
        $(document.body).removeClass('no-scroll');
      }
    }

    if($(e.currentTarget).data('action') == 'close') {
      $(`${this.element}[data-target="${$targetString}"]`).focus();
    }

    $target.attr('aria-expanded', expanded);
    $(`${this.element}[data-target="${$targetString}"]`).not(e.currentTarget).removeClass(this.openClass);
  }
  closeOthers($target = '') {
    $(`.${this.openClass}`).not($target).removeClass(this.openClass);
  }
}

export function initToggle(element, settings = {}) {
  new Toggle(element, settings);
}


export function ToggleComponent(element) {
  const target=  element.dataset.target;
  let isOpen = false;
  const OPEN_CLASS = 'is-open';

  function handleOpen(e) {
    e.preventDefault();
    isOpen = !isOpen;
    if(isOpen) {
      e.target.classList.add(OPEN_CLASS);
    } else {
      e.target.classList.remove(OPEN_CLASS);
    }
  }

  element.addEventListener('keyup', e => {console.log(e)});
  element.addEventListener('click', handleOpen);
}
