import * as delegate from 'delegate';

export default class Tabs {
  private elem: HTMLElement;

  static factory(): void {
    document.querySelectorAll('[data-tabs]').forEach((elem: HTMLElement) => {
      new Tabs(elem);
    });
  }

  constructor(elem: HTMLElement) {
    this.elem = elem;

    const activeElem: HTMLElement = document.querySelector('[data-tabs-nav] [data-tabs-item]');
    this.setActiveTab(activeElem.dataset.tabsItem);
    this.addListeners();
  }

  private addListeners(): void {
    delegate(this.elem, '[data-tabs-nav] [data-tabs-item]', 'click', (e) => {
      e.preventDefault();
      this.setActiveTab(e.delegateTarget.dataset.tabsItem)
    });
  }

  private setActiveTab(target: string): void {
    this.elem.querySelectorAll('[data-tabs-content] [data-tabs-item]').forEach((elem: HTMLElement) => {
      elem.style.display = target === elem.dataset.tabsItem ? 'block' : 'none';
    });

    this.elem.querySelectorAll('[data-tabs-nav] [data-tabs-item]').forEach((elem: HTMLElement) => {
      const active = target === elem.dataset.tabsItem;
      elem.classList.remove('tabs__nav-item--active');

      if (active) {
        elem.classList.add('tabs__nav-item--active');
      }

      elem.setAttribute('aria-selected', active ? 'true' : 'false');
    });
  }
}