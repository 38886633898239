import {get} from "lodash";
import axios from 'axios';
import * as qs from 'qs';
import {Resource} from 'i18next';
import SiteHeader from './compontents/site-header';
// import Dropdown from './compontents/dropdown';
import * as LazyLoad from 'vanilla-lazyload/dist/lazyload';
import * as i18next from 'i18next/i18next.js';
import Modal from "./compontents/modal";
import ViewPort from "./viewport";
import Tabs from "./compontents/tabs";
import blocks from '../../blocks/js/blocks';
import Gallery from "./compontents/gallery";
import {
  SubmenuToggle
} from "./compontents/MobileSubmenu";
import * as Glide from '@glidejs/glide/dist/glide';
import { initMenu } from "./compontents/menu";
// import { initToggle } from "./compontents/toggle";
initMenu(".js-menu-trigger");
// initToggle(".js-toggle");
SubmenuToggle();
// const glide = new Glide('.glide', {
//   type: 'carousel',
//   perView: 4,
//   focusAt: 'center',
//   breakpoints: {
//     800: {
//       perView: 2
//     },
//     480: {
//       perView: 1
//     }
//   }
// });

declare global {
  interface Window {
    pp: {
      data: Record<string, any>;
      translations: Record<string, any>;
    };
  }
}
declare let __webpack_public_path__: string;

export default class App {
  static instance: App;

  static init(): void {
    if (!App.instance) {
      App.instance = new App();
    }
  }

  constructor() {
    App._loadTranslations();

    ViewPort.setBreakpoints({
      xs: 0,
      sm: 540,
      md: 768,
      lg: 1024,
      xl: 1200
    });

    __webpack_public_path__ = App.getData('publicPath');

    axios.defaults.baseURL = App.getData('ajaxUrl');
    axios.interceptors.request.use(config => {
      config.paramsSerializer = params => qs.stringify(params);
      return config;
    });

    window.addEventListener('load', () => {
      new SiteHeader(document.querySelector('._js-header'));
    });

    new LazyLoad();

    const slider = document.querySelector('.glide-slider');

    if (slider) {
      const glide = new Glide(slider, {
        type: 'carousel',
        perView: 1
      });

      glide.mount();
    }

    const timeline = document.querySelector('.glide-timeline');

    if (timeline) {
      const glideTimeline = new Glide(timeline, {
        type: 'carousel',
        perView: 4,
        focusAt: 1,
        startAt:1,
        breakpoints: {
          1400: {
            perView: 4,
            focusAt: 1,
            startAt:1,
          },
          600: {
            perView: 1,
            focusAt:0,
            startAt:0,
          }
        }
      });

      glideTimeline.mount();
    }

    blocks();
    Modal.factory();
    Tabs.factory();
    Gallery.factory();
    // Dropdown.factory();
  }

  public static getData(key: string): any {
    return get(window.pp.data, key);
  }

  private static _loadTranslations(): void {
    const translationResources: Resource = {};
    translationResources[App.getData('lang')] = {
      translation: window.pp.translations
    };

    i18next.init({
      lng: App.getData('lang'),
      initImmediate: false,
      resources: translationResources
    });
  }
}

App.init();