/*eslint-disable*/

import $ from 'jquery';
import { Toggle } from "./toggle";

export class Menu extends Toggle {
  constructor(trigger) {
    super(trigger);
    // this.getTarget(trigger).focusout((e) => {
    //   console.log($(e.relatedTarget));
    //   console.log($(e.relatedTarget).parents('.js-menu').length);
    //   if(! $(e.relatedTarget).parents('.js-menu').length) {
    //     console.log('CLOSE MENU');
    //     this.close(trigger);
    //   }
    // });
  }
  getAction(trigger) {
    return $(trigger).data('action');
  }

  close(trigger) {
    $(this.trigger).removeClass(this.openClass);
    this.getTarget(trigger).removeClass(this.openClass);
    $(document.body).removeClass('nav-is-open');
  }
  getTarget(trigger) {
    return $($(trigger).data('target'));
  }
  toggle(e) {
    this.preventDefault(e);
    this.closeOthers();
    let trigger = e.currentTarget;
    const $menu = this.getTarget(trigger);
    if(this.getAction(trigger) == 'open') {
      $(trigger).addClass(this.openClass);
      $menu.addClass(this.openClass).focus();
      $(document.body).addClass('nav-is-open');
    } else {
      this.close(trigger);
    }
  }
}

export function initMenu(element) {
  const menu = new Menu(element);
}